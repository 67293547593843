import "core-js/modules/es6.array.sort";
import "core-js/modules/web.dom.iterable";
import "core-js/modules/es6.regexp.to-string";
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//

import { agentFlowerNameList, getShareList, FollwupIndex } from '@/api/ceshi_xiugai/whole';
import { getStructureListArr, AllCustomer } from '@/api/PublicAPI';
import { getlower_structure_list, getUserList } from '@/api/updateUserInfo/structure';
import Head from '@/components/head/index';
import studentLevel from '@/components/studentLevel/index';
import ExportExcel from '@/components/ExportExcel/index';
export default {
  name: 'AllData',
  data: function data() {
    return {
      // 系统教程
      tab: 1,
      activeName: 'first',
      course: this.$route.query.course,
      prohibit: false,
      // 系统教程
      // 埋点是否显示
      BuriedData1: [],
      Buried: {
        search_username: '',
        search_realname: '',
        search_create_time: '',
        search_structure_ids: '',
        search_auth_rule_title: ''
      },
      finalFollow: '',
      structure_id: '',
      bumenoptions: [],
      start_follow_time: '',
      //时间（开始）
      end_follow_time: '',
      //时间（结束）
      seeJudge: false,
      searchJudge: false,
      typeShow: false,
      operatorShow: false,
      userNameshow: false,
      BelongingShow: false,
      operationDataShow: false,
      comPlatshow: false,
      comNameshow: false,
      comWebsiteshow: false,
      comPlat: '',
      comName: '',
      comWebsite: '',
      followUp: false,
      activities: [],
      //跟进时间线
      // 埋点是否显示
      title: '全部客户',
      query: {
        page: 1,
        pagesize: 10,
        total: 0 //总条数，不是总页数
      },
      buried: [],
      auth_rule_id: '',
      is_director: this.$store.state.user.is_director,
      //是否是主管
      listLoading: false,
      follow_page: 1,
      //跟进人分页
      follow_pagesize: 20,
      //跟进人多少条数据
      founder_page: 1,
      //创建人分页
      founder_pagesize: 20,
      //创建人多少条数据
      follow_Arr: [],
      //筛选跟进人数组
      source_client_id: '',
      //学员来源默认值
      flower_Array: [],
      //学员来源数据
      tableData: [],
      //全部数据列表
      sort: {
        //时间排序
        establish: 0,
        //创建日期
        lastFollowUp: 0,
        //最后跟进日期
        private: 0,
        //进入私海日期
        updateNum: 0 //最后更新日期
      },
      screen: {
        //筛选条件显示/隐藏
        DepartmentShow: false,
        //跟进人
        FollowUpPersonShow: false,
        //跟进人
        creationDateShow: true,
        //创建日期
        loading: false,
        attributionShow: false,
        //归属地
        founderShow: false,
        //创建人
        founderEvelShow: false,
        //创建人所属部门
        intentionShow: false,
        //意向地区
        lastFollowUpShow: false,
        //最后跟进日期
        thePrivateSeaShow: false,
        //进入私海日期
        lastUpdateShow: false,
        //最后更新日期
        originalShow: false //原始创建人
      },
      screeningTime: {
        //筛选时间开始/结束
        establishStart: '',
        //创建时间开始
        establishend: '',
        //创建时间结束
        follow_stime: '',
        //最后跟进日期开始
        follow_etime: '',
        //最后跟进日期结束
        sea_stime: '',
        //进入私海日期开始
        sea_etime: '',
        //进入私海日期结束
        update_stime: '',
        //最后更新日期开始
        update_etime: '' //最后更新日期结束
      },
      defaultValue: {
        //筛选条件默认值
        ClassSearch: '',
        //名称电话
        Department_id: '',
        //数据所属部门
        Department_Name: '',
        creationDateName: [],
        //创建日期
        FollowUpPerson_name: '',
        //跟进人
        customer_levels: '',
        //学员级别
        follow_Id: '',
        //跟进人
        follow_name: '',
        //跟进人
        attributionName: '',
        //归属地
        founder_Id: '',
        //创建人
        founder_name: '',
        //创建人
        founderEvel_id: '',
        //创建人所属部门
        founderEvel_Name: '',
        //创建人所属部门
        intentionName: '',
        //意向地区
        originalName: '',
        //原始创建人
        lastFollowUpDate: '',
        //最后跟进日期
        thePrivateSea: '',
        //进入私海日期
        lastUpdate: '' ////最后更新日期
      },
      defaultArray: {
        //筛选条件数组
        Department: [],
        //数据所属部门数组
        founder_Arr: [],
        //创建人数组
        founderEvelArrye: [] //创建人所属部门
      }
    };
  },
  created: function created() {
    //当前设定的日期时间
    var d = new Date();
    var year1, month1, day1;
    var _ref = [d.getFullYear(), d.getMonth(), d.getDate()];
    year1 = _ref[0];
    month1 = _ref[1];
    day1 = _ref[2];
    var date1 = new Date(year1, month1, day1, 23, 59, 59);
    this.defaultValue.creationDateName.push(date1);
    //前一天设定的日期时间
    var year2, month2, day2;
    d.setTime(d.getTime() - 24 * 60 * 60 * 1000);
    var _ref2 = [d.getFullYear(), d.getMonth(), d.getDate()];
    year2 = _ref2[0];
    month2 = _ref2[1];
    day2 = _ref2[2];
    var date2 = new Date(year2, month2, 0, 24);
    this.defaultValue.creationDateName.unshift(date2);
    this.screeningTime.screeningTime = Date.parse(this.defaultValue.creationDateName[0]).toString().substring(0, 10);
    this.screeningTime.establishend = Date.parse(this.defaultValue.creationDateName[1]).toString().substring(0, 10);
  },
  mounted: function mounted() {
    var _this = this;
    this.buried = this.$store.getters.JsonArr1;
    if (this.course != 1) {
      this.buriedPoint('查看');
      this.showHiddin();
      this.getList(this.query.page, this.query.pagesize);
      this.followFun();
      this.followFunEs();
      // 控制表格滚动条
      var that = this;
      this.$nextTick(function () {
        var mutationObserver = new MutationObserver(function (mutations) {
          that.setHeight();
        });
        mutationObserver.observe(_this.$refs.multipleTable.bodyWrapper, {
          attributes: true,
          subtree: true
        });
      });
    } else {}
  },
  components: {
    Head: Head,
    studentLevel: studentLevel,
    ExportExcel: ExportExcel
  },
  methods: {
    showHiddin: function showHiddin() {
      var JsonArr1 = this.$store.getters.Atter;
      if (JsonArr1.indexOf('查看') != -1) {
        this.seeJudge = true;
      }
      if (JsonArr1.indexOf('搜索') != -1) {
        this.searchJudge = true;
      }
    },
    // 埋点列表筛选
    operatorClick: function operatorClick() {
      //操作人
      this.query.page = 1;
      this.statisticalTable(this.query.page, this.query.pagesize);
    },
    userNamesClick: function userNamesClick() {
      //用户名
      this.query.page = 1;
      this.statisticalTable(this.query.page, this.query.pagesize);
    },
    noteAppendedClick: function noteAppendedClick() {
      this.query.page = 1;
      this.statisticalTable(this.query.page, this.query.pagesize);
    },
    FinalFollowUpEs: function FinalFollowUpEs() {
      //最后跟进日期筛选
      this.query.page = 1;
      if (this.finalFollow == null) {
        this.start_follow_time = '';
        this.end_follow_time = '';
        this.Buried.search_create_time = this.start_follow_time + ',' + this.end_follow_time;
        this.statisticalTable(this.query.page, this.query.pagesize);
      } else {
        this.start_follow_time = this.finalFollow[0].toString().substring(0, 10);
        this.end_follow_time = this.finalFollow[1].toString().substring(0, 10);
        this.Buried.search_create_time = this.start_follow_time + ',' + this.end_follow_time;
        this.statisticalTable(this.query.page, this.query.pagesize);
      }
    },
    //组织部门
    structureclick: function structureclick(val) {
      this.query.page = 1;
      var share = val.map(function (e) {
        return e[e.length - 1];
      }).toString(); // 部门的最后一位ID
      var adad = share == ',' ? '' : share;
      this.Buried.search_structure_ids = adad;
      this.statisticalTable(this.query.page, this.query.pagesize);
    },
    // 埋点列表筛选
    handleClick: function handleClick() {
      if (this.activeName == 'second') {
        this.query.page = 1;
        this.statisticalTable(this.query.page, this.query.pagesize);
      }
      this.structure_id = '';
      this.finalFollow = '';
      this.typeShow = false;
      this.operatorShow = false;
      this.userNameshow = false;
      this.BelongingShow = false;
      this.operationDataShow = false;
      this.Buried = {
        search_username: '',
        search_realname: '',
        search_create_time: '',
        search_structure_ids: '',
        search_auth_rule_title: ''
      };
    },
    statisticalTable: function statisticalTable(page, pagesize) {
      var _this2 = this;
      this.Buried.page = page;
      this.Buried.pagesize = pagesize;
      this.Buried.auth_rule_pid = this.menuId;
      OperatorDataIndex(this.Buried).then(function (res) {
        _this2.BuriedData1 = res.data.data;
        _this2.query.total = res.data.total;
      });
    },
    getStructureSe: function getStructureSe() {
      var _this3 = this;
      //组织部门
      getStructureListArr().then(function (respomse) {
        _this3.bumenoptions = respomse.data;
      });
    },
    buriedPoint: function buriedPoint(name) {
      var _this4 = this;
      this.buried.forEach(function (item) {
        if (item.title == name) {
          _this4.auth_rule_id = item.id;
        }
      });
      return this.auth_rule_id;
    },
    getList: function getList(page, pagesize) {
      var _this5 = this;
      //列表数据
      this.listLoading = true;
      AllCustomer({
        page: String(page),
        pagesize: String(pagesize),
        auth_rule_id: this.auth_rule_id,
        mobile_cname: String(this.defaultValue.ClassSearch),
        source_client_ids: String(this.source_client_id),
        customer_levels: String(this.defaultValue.customer_levels),
        follow_user_ids: String(this.defaultValue.follow_name),
        follow_structuress: String(this.defaultValue.Department_Name),
        creates_time: this.screeningTime.screeningTime,
        createe_time: this.screeningTime.establishend,
        create_time_order: this.sort.establish,
        mobile_address: this.defaultValue.attributionName,
        create_user_ids: String(this.defaultValue.founder_name),
        create_structuress: this.defaultValue.founderEvel_Name,
        ittngj: this.defaultValue.intentionName,
        follow_stime: this.screeningTime.follow_stime,
        follow_etime: this.screeningTime.follow_etime,
        follow_time_order: this.sort.lastFollowUp,
        sea_stime: this.screeningTime.sea_stime,
        sea_etime: this.screeningTime.sea_etime,
        sea_time_order: this.sort.private,
        update_stime: this.screeningTime.update_stime,
        update_etime: this.screeningTime.update_stime,
        update_time_order: this.sort.updateNum,
        old_create_realname: this.defaultValue.originalName,
        platform_name: this.comPlat,
        communication_name: this.comName,
        communication_website: this.comWebsite
      }).then(function (res) {
        _this5.listLoading = false;
        _this5.tableData = res.data.data;
        _this5.query.total = res.data.total;
      });
    },
    setHeight: function setHeight() {
      // 设置滚动条相关信息
      var widthValue = document.getElementById('customerTable').clientWidth + 'px';
      this.tableBodyWidth = document.getElementById('customerTable').childNodes[1].childNodes[0].clientWidth + 'px';
      var div = document.getElementById('tableLeft');
      div.style.width = widthValue;
      var div2 = document.getElementById('bodyLeft');
      div2.style.width = this.tableBodyWidth;
    },
    // 控制表格滚动条
    exterHandleScroll: function exterHandleScroll() {
      var table = this.$refs.multipleTable.bodyWrapper;
      table.scrollLeft = this.$refs.tableLeft.scrollLeft;
    },
    flowerArrayEs: function flowerArrayEs(val) {
      //学员来源选择后刷新表格
      this.query.page = 1;
      this.buriedPoint('搜索');
      this.getList(this.query.page, this.query.pagesize);
    },
    ClassSearchInput: function ClassSearchInput() {
      this.query.page = 1;
      this.buriedPoint('搜索');
      this.getList(this.query.page, this.query.pagesize);
    },
    flowerArray: function flowerArray() {
      var _this6 = this;
      //学员来源获取焦点获取数据
      agentFlowerNameList().then(function (res) {
        _this6.flower_Array = res.data;
      });
    },
    studentLevel: function studentLevel(val) {
      // 学员级别
      this.defaultValue.customer_levels = val;
      this.query.page = 1;
      this.buriedPoint('搜索');
      this.getList(this.query.page, this.query.pagesize);
    },
    followFun: function followFun() {
      var _this7 = this;
      //筛选跟进人数据
      getShareList({
        page: String(this.follow_page),
        pagesize: String(this.follow_pagesize)
      }).then(function (res) {
        _this7.follow_Arr = res.data.data;
        // this.defaultArray.founder_Arr = res.data.data;
      });
    },
    followUpCustomers: function followUpCustomers(id) {
      var _this8 = this;
      //跟进弹窗
      this.activities = '';
      this.followUp = true;
      FollwupIndex({
        customer_data_id: id.cd_id,
        id: id.cid,
        is_realname: 1
      }).then(function (res) {
        res.data.map(function (res) {
          if (res.type == 1) {
            res.type = '电话';
          } else if (res.type == 2) {
            res.type = 'QQ';
          } else if (res.type == 3) {
            res.type = '微信';
          } else if (res.type == 4) {
            res.type = '面聊';
          }
          res.next_time = String(res.next_time);
        });
        _this8.activities = res.data;
      });
    },
    followFunEs: function followFunEs() {
      var _this9 = this;
      //筛选创建人数据
      getUserList({
        get_type: '6',
        type: '0'
      }).then(function (res) {
        _this9.follow_Arr = res.data;
        _this9.defaultArray.founder_Arr = res.data;
      });
    },
    followName: function followName() {
      var _this10 = this;
      //跟进人
      if (this.defaultValue.follow_name == '') {
        this.follow_page++;
        getUserList({
          page: String(this.follow_page),
          pagesize: String(this.follow_pagesize)
        }).then(function (res) {
          res.data.data.forEach(function (item) {
            _this10.follow_Arr.push(item);
          });
        });
      }
    },
    followMethod: function followMethod(val) {
      var _this11 = this;
      //跟进人
      this.defaultValue.follow_name = val;
      this.follow_page = 1;
      getShareList({
        realname: val,
        page: String(this.follow_page),
        pagesize: String(this.follow_pagesize)
      }).then(function (res) {
        _this11.follow_Arr = res.data.data;
      });
    },
    followidEs: function followidEs(val) {
      //跟进人
      this.defaultValue.follow_name = val;
      this.$refs.clearValue.query = '';
      this.query.page = 1;
      this.buriedPoint('搜索');
      this.getList(this.query.page, this.query.pagesize);
    },
    founderName: function founderName() {
      var _this12 = this;
      //创建人
      if (this.defaultValue.founder_name == '') {
        this.founder_page++;
        getShareList({
          page: String(this.founder_page),
          pagesize: String(this.founder_pagesize)
        }).then(function (res) {
          res.data.data.forEach(function (item) {
            _this12.defaultArray.founder_Arr.push(item);
          });
        });
      }
    },
    founderMethod: function founderMethod(val) {
      var _this13 = this;
      //创建人
      this.defaultValue.founder_name = val;
      this.founder_page = 1;
      getUserList({
        get_type: '6',
        type: '0',
        real_name: String(this.defaultValue.founder_name)
      }).then(function (res) {
        _this13.defaultArray.founder_Arr = res.data;
      });
    },
    founderEs: function founderEs(val) {
      //创建人
      this.defaultValue.founder_name = val;
      this.$refs.clearValueEs.query = '';
      this.query.page = 1;
      this.buriedPoint('搜索');
      this.getList(this.query.page, this.query.pagesize);
    },
    handleSelectionChange: function handleSelectionChange(val) {
      //表格点击复选框获取选中id
    },
    attributionClick: function attributionClick() {
      //归属地查询
      this.query.page = 1;
      this.buriedPoint('搜索');
      this.getList(this.query.page, this.query.pagesize);
    },
    sortEs: function sortEs(column, prop, order) {
      // 点击表头排序
      // ascending 升序 、 descending降序 、 null 不排序
      if (column.prop == 'create_time') {
        //创建日期
        if (column.order == 'ascending') {
          this.sort.establish = '1';
        } else if (column.order == 'descending') {
          this.sort.establish = '2';
        } else {
          this.sort.establish = '0';
        }
        this.sort.updateNum = '0';
        this.sort.private = '0';
        this.sort.lastFollowUp = '0';
      } else if (column.prop == 'follw_time') {
        //最后跟进日期
        if (column.order == 'ascending') {
          this.sort.lastFollowUp = '1';
        } else if (column.order == 'descending') {
          this.sort.lastFollowUp = '2';
        } else {
          this.sort.lastFollowUp = '0';
        }
        this.sort.updateNum = '0';
        this.sort.private = '0';
        this.sort.establish = '0';
      } else if (column.prop == 'sea_time') {
        //进入私海日期
        if (column.order == 'ascending') {
          this.sort.private = '1';
        } else if (column.order == 'descending') {
          this.sort.private = '2';
        } else {
          this.sort.private = '0';
        }
        this.sort.updateNum = '0';
        this.sort.establish = '0';
        this.sort.lastFollowUp = '0';
      } else if (column.prop == 'update_time') {
        //进入私海日期
        if (column.order == 'ascending') {
          this.sort.updateNum = '1';
        } else if (column.order == 'descending') {
          this.sort.updateNum = '2';
        } else {
          this.sort.updateNum = '0';
        }
        this.sort.private = '0';
        this.sort.establish = '0';
        this.sort.lastFollowUp = '0';
      }
      this.query.page = 1;
      this.buriedPoint('搜索');
      this.getList(this.query.page, this.query.pagesize);
    },
    // 所有筛选事件
    ment: function ment() {
      var _this14 = this;
      //数据所属部门获取焦点数组赋值
      getStructureListArr().then(function (respomse) {
        _this14.defaultArray.Department = respomse.data;
      });
    },
    founderEvelFocus: function founderEvelFocus() {
      var _this15 = this;
      //创建人所属部门获取焦点数组赋值
      getlower_structure_list({
        get_type: '6',
        type: '1'
      }).then(function (respomse) {
        _this15.defaultArray.founderEvelArrye = respomse.data;
      });
    },
    DepartmentClick: function DepartmentClick(val) {
      //数据所属部门选中获取最子级ID
      var share = val.map(function (e) {
        return e[e.length - 1];
      }).toString();
      var adad = share == ',' ? '' : share;
      this.defaultValue.Department_Name = adad;
      this.query.page = 1;
      this.buriedPoint('搜索');
      this.getList(this.query.page, this.query.pagesize);
    },
    founderEvelClick: function founderEvelClick(val) {
      //创建人所属部门选中获取最子级ID
      var share = val.map(function (e) {
        return e[e.length - 1];
      }).toString();
      var adad = share == ',' ? '' : share;
      this.defaultValue.founderEvel_Name = adad;
      this.query.page = 1;
      this.buriedPoint('搜索');
      this.getList(this.query.page, this.query.pagesize);
    },
    creationDateTime: function creationDateTime() {
      //创建时间
      if (this.defaultValue.creationDateName == null) {
        this.screeningTime.screeningTime = '';
        this.screeningTime.establishend = '';
      } else {
        this.screeningTime.screeningTime = this.defaultValue.creationDateName[0].toString().substring(0, 10);
        this.screeningTime.establishend = this.defaultValue.creationDateName[1].toString().substring(0, 10);
      }
      this.query.page = 1;
      this.buriedPoint('搜索');
      this.getList(this.query.page, this.query.pagesize);
    },
    lastFollowUpClick: function lastFollowUpClick() {
      //最后跟进日期
      if (this.defaultValue.lastFollowUpDate == null) {
        this.screeningTime.follow_stime = '';
        this.screeningTime.follow_etime = '';
      } else {
        this.screeningTime.follow_stime = this.defaultValue.lastFollowUpDate[0].toString().substring(0, 10);
        this.screeningTime.follow_etime = this.defaultValue.lastFollowUpDate[1].toString().substring(0, 10);
      }
      this.query.page = 1;
      this.buriedPoint('搜索');
      this.getList(this.query.page, this.query.pagesize);
    },
    thePrivateSeaClick: function thePrivateSeaClick() {
      //进入私海日期
      if (this.defaultValue.thePrivateSea == null) {
        this.screeningTime.sea_stime = '';
        this.screeningTime.sea_etime = '';
      } else {
        this.screeningTime.sea_stime = this.defaultValue.thePrivateSea[0].toString().substring(0, 10);
        this.screeningTime.sea_etime = this.defaultValue.thePrivateSea[1].toString().substring(0, 10);
      }
      this.query.page = 1;
      this.buriedPoint('搜索');
      this.getList(this.query.page, this.query.pagesize);
    },
    lastUpdateClick: function lastUpdateClick() {
      //最后更新日期
      if (this.defaultValue.lastUpdate == null) {
        this.screeningTime.update_stime = '';
        this.screeningTime.update_etime = '';
      } else {
        this.screeningTime.update_stime = this.defaultValue.lastUpdate[0].toString().substring(0, 10);
        this.screeningTime.update_etime = this.defaultValue.lastUpdate[1].toString().substring(0, 10);
      }
      this.query.page = 1;
      this.buriedPoint('搜索');
      this.getList(this.query.page, this.query.pagesize);
    },
    intentionClick: function intentionClick() {
      //意向地区
      this.buriedPoint('搜索');
      this.getList(this.query.page, this.query.pagesize);
    },
    originalClick: function originalClick() {
      //原始创建人
      this.query.page = 1;
      this.buriedPoint('搜索');
      this.getList(this.query.page, this.query.pagesize);
    },
    comNameClick: function comNameClick() {
      this.query.page = 1;
      this.getList(this.query.page, this.query.pagesize);
    },
    comPlatClick: function comPlatClick() {
      this.query.page = 1;
      this.getList(this.query.page, this.query.pagesize);
    },
    comWebsiteClick: function comWebsiteClick() {
      this.query.page = 1;
      this.getList(this.query.page, this.query.pagesize);
    },
    Activeshow: function Activeshow(item) {
      //显示当前筛选条件
      if (item === '跟进人') {
        this.screen.FollowUpPersonShow = true;
      } else if (item === '数据所属部门') {
        this.screen.DepartmentShow = true;
      } else if (item === '创建日期') {
        this.screen.creationDateShow = true;
      } else if (item === '归属地') {
        this.screen.attributionShow = true;
      } else if (item === '创建人') {
        this.screen.founderShow = true;
      } else if (item === '创建人所属部门') {
        this.screen.founderEvelShow = true;
      } else if (item === '意向地区') {
        this.screen.intentionShow = true;
      } else if (item === '最后跟进日期') {
        this.screen.lastFollowUpShow = true;
      } else if (item === '进入私海日期') {
        this.screen.thePrivateSeaShow = true;
      } else if (item === '最后更新日期') {
        this.screen.lastUpdateShow = true;
      } else if (item === '原始创建人') {
        this.screen.originalShow = true;
      } else if (item === '操作人') {
        this.operatorShow = true;
      } else if (item === '用户名') {
        this.userNameshow = true;
      } else if (item === '操作日期') {
        this.operationDataShow = true;
      } else if (item === '操作人所属部门') {
        this.BelongingShow = true;
      } else if (item === '操作类型') {
        this.typeShow = true;
      } else if (item === '推广平台') {
        this.comPlatshow = true;
      } else if (item === '推广项目') {
        this.comNameshow = true;
      } else if (item === '推广地址') {
        this.comWebsiteshow = true;
      }
    },
    Deletesshow: function Deletesshow(item) {
      //隐藏当前筛选条件
      if (item === '跟进人') {
        this.screen.FollowUpPersonShow = false;
        this.defaultValue.follow_name = '';
      } else if (item === '数据所属部门') {
        this.screen.DepartmentShow = false;
        this.defaultValue.Department_id = '';
        this.defaultValue.Department_Name = '';
      } else if (item === '创建日期') {
        this.screen.creationDateShow = false;
        this.defaultValue.creationDateName = '';
        this.screeningTime.screeningTime = '';
        this.screeningTime.establishend = '';
      } else if (item === '归属地') {
        this.screen.attributionShow = false;
        this.defaultValue.attributionName = '';
      } else if (item === '创建人') {
        this.screen.founderShow = false;
        this.defaultValue.founder_Id = '';
        this.defaultValue.founder_name = '';
      } else if (item === '创建人所属部门') {
        this.screen.founderEvelShow = false;
        this.defaultValue.founderEvel_id = '';
        this.defaultValue.founderEvel_Name = '';
      } else if (item === '意向地区') {
        this.screen.intentionShow = false;
        this.defaultValue.intentionName = '';
      } else if (item === '最后跟进日期') {
        this.screen.lastFollowUpShow = false;
        this.defaultValue.lastFollowUpDate = '';
        this.screeningTime.follow_stime = '';
        this.screeningTime.follow_etime = '';
      } else if (item === '进入私海日期') {
        this.screen.thePrivateSeaShow = false;
        this.defaultValue.thePrivateSea = '';
        this.screeningTime.sea_stime = '';
        this.screeningTime.sea_etime = '';
      } else if (item === '最后更新日期') {
        this.screen.lastUpdateShow = false;
        this.defaultValue.lastUpdate = '';
        this.screeningTime.update_stime = '';
        this.screeningTime.update_etime = '';
      } else if (item === '原始创建人') {
        this.screen.originalShow = false;
        this.defaultValue.originalName = '';
      } else if (item === '操作人') {
        this.operatorShow = false;
        this.Buried.search_realname = '';
      } else if (item === '用户名') {
        this.userNameshow = false;
        this.Buried.search_username = '';
      } else if (item === '操作日期') {
        this.operationDataShow = false;
        this.Buried.search_create_time = '';
      } else if (item === '操作人所属部门') {
        this.BelongingShow = false;
        this.structure_id = '';
        this.Buried.search_structure_ids = '';
      } else if (item === '操作类型') {
        this.typeShow = false;
        this.Buried.search_auth_rule_title = '';
        this.statisticalTable(this.query.page, this.query.pagesize);
      } else if (item === '推广平台') {
        this.comPlatshow = false;
        this.comPlat = '';
      } else if (item === '推广项目') {
        this.comNameshow = false;
        this.comName = '';
      } else if (item === '推广地址') {
        this.comWebsiteshow = false;
        this.comWebsite = '';
      }
      this.query.page = 1;
      if (this.course != 1) {
        this.buriedPoint('搜索');
        this.getList(this.query.page, this.query.pagesize);
      } else {
        this.statisticalTable(this.query.page, this.query.pagesize);
      }
    },
    // 所有筛选事件
    handleSizeChange: function handleSizeChange(val) {
      //每页 ${val} 条
      this.query.pagesize = val;
      if (this.course != 1) {
        this.buriedPoint('搜索');
        this.getList(this.query.page, val);
      } else {
        this.statisticalTable(this.query.page, val);
      }
    },
    handleCurrentChange: function handleCurrentChange(val) {
      //当前页
      this.query.page = val;
      if (this.course != 1) {
        this.buriedPoint('搜索');
        this.getList(val, this.query.pagesize);
      } else {
        this.statisticalTable(val, this.query.pagesize);
      }
    }
  }
};
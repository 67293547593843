var render = function () {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c("div", { staticClass: "app-container allData" }, [
    _vm.course != 1
      ? _c(
          "div",
          { staticStyle: { width: "100%" } },
          [
            _c(
              "el-header",
              { staticStyle: { height: "auto" } },
              [
                _c(
                  "el-row",
                  { staticStyle: { height: "32px" }, attrs: { gutter: 24 } },
                  [_c("Head", { attrs: { name: _vm.title } })],
                  1
                ),
                _vm._v(" "),
                _c(
                  "el-row",
                  {
                    staticClass: "btn",
                    staticStyle: { "margin-right": "0" },
                    attrs: { gutter: 24 },
                  },
                  [
                    _vm.searchJudge
                      ? _c(
                          "el-col",
                          {
                            staticStyle: { "padding-right": "0" },
                            attrs: { span: 20 },
                          },
                          [
                            _c(
                              "div",
                              {
                                staticClass: "source",
                                staticStyle: { "padding-top": "20px" },
                              },
                              [
                                _c("span", { staticClass: "nameTitle" }, [
                                  _vm._v("学员来源："),
                                ]),
                                _vm._v(" "),
                                _c(
                                  "el-select",
                                  {
                                    staticStyle: {
                                      width: "180px",
                                      "margin-right": "0",
                                    },
                                    attrs: {
                                      size: "small",
                                      filterable: "",
                                      "no-data-text": "加载中...",
                                      placeholder: "请搜索花名",
                                      clearable: "",
                                    },
                                    on: {
                                      change: _vm.flowerArrayEs,
                                      focus: _vm.flowerArray,
                                    },
                                    model: {
                                      value: _vm.source_client_id,
                                      callback: function ($$v) {
                                        _vm.source_client_id = $$v
                                      },
                                      expression: "source_client_id",
                                    },
                                  },
                                  _vm._l(_vm.flower_Array, function (item) {
                                    return _c("el-option", {
                                      key: item.id,
                                      attrs: {
                                        label: item.flower_name,
                                        value: item.id,
                                      },
                                    })
                                  }),
                                  1
                                ),
                              ],
                              1
                            ),
                            _vm._v(" "),
                            _c(
                              "div",
                              {
                                staticClass: "source",
                                staticStyle: { "padding-top": "20px" },
                              },
                              [
                                _c("student-level", {
                                  on: { studentLevel: _vm.studentLevel },
                                }),
                              ],
                              1
                            ),
                          ]
                        )
                      : _vm._e(),
                    _vm._v(" "),
                    _vm.searchJudge
                      ? _c(
                          "el-col",
                          {
                            staticStyle: {
                              "padding-right": "0",
                              "padding-top": "20px",
                            },
                            attrs: { span: 4 },
                          },
                          [
                            _c(
                              "el-input",
                              {
                                staticClass: "input-with-select",
                                attrs: {
                                  placeholder: "请搜索电话、名称",
                                  size: "small",
                                },
                                on: { change: _vm.ClassSearchInput },
                                model: {
                                  value: _vm.defaultValue.ClassSearch,
                                  callback: function ($$v) {
                                    _vm.$set(
                                      _vm.defaultValue,
                                      "ClassSearch",
                                      $$v
                                    )
                                  },
                                  expression: "defaultValue.ClassSearch",
                                },
                              },
                              [
                                _c("el-button", {
                                  attrs: {
                                    slot: "append",
                                    type: "primary",
                                    size: "small",
                                    icon: "el-icon-search",
                                  },
                                  on: { click: _vm.ClassSearchInput },
                                  slot: "append",
                                }),
                              ],
                              1
                            ),
                          ],
                          1
                        )
                      : _vm._e(),
                  ],
                  1
                ),
                _vm._v(" "),
                _c(
                  "el-row",
                  { attrs: { gutter: 24 } },
                  [
                    _c(
                      "el-col",
                      { attrs: { xs: 24, sm: 16, md: 13, lg: 11, xl: 11 } },
                      [
                        _c("div", {
                          directives: [
                            {
                              name: "permission",
                              rawName: "v-permission",
                              value: [""],
                              expression: "['']",
                            },
                          ],
                        }),
                        _vm._v(" "),
                        _c(
                          "div",
                          {
                            directives: [
                              {
                                name: "show",
                                rawName: "v-show",
                                value: _vm.screen.FollowUpPersonShow,
                                expression: "screen.FollowUpPersonShow",
                              },
                            ],
                            staticStyle: { "margin-top": "15px" },
                          },
                          [
                            _c(
                              "span",
                              { staticClass: "nameTitle projectScreening" },
                              [_vm._v("跟进人：")]
                            ),
                            _vm._v(" "),
                            _c(
                              "el-select",
                              {
                                directives: [
                                  {
                                    name: "loadmore",
                                    rawName: "v-loadmore",
                                    value: _vm.followName,
                                    expression: "followName",
                                  },
                                ],
                                ref: "clearValue",
                                staticStyle: { "margin-left": "20px" },
                                attrs: {
                                  multiple: "",
                                  filterable: "",
                                  remote: "",
                                  "reserve-keyword": "",
                                  size: "small",
                                  "collapse-tags": "",
                                  loading: _vm.screen.loading,
                                  "remote-method": _vm.followMethod,
                                  placeholder: "请选择",
                                },
                                on: { change: _vm.followidEs },
                                model: {
                                  value: _vm.defaultValue.follow_Id,
                                  callback: function ($$v) {
                                    _vm.$set(_vm.defaultValue, "follow_Id", $$v)
                                  },
                                  expression: "defaultValue.follow_Id",
                                },
                              },
                              _vm._l(_vm.follow_Arr, function (item) {
                                return _c("el-option", {
                                  key: item.id,
                                  attrs: {
                                    label: item.realname,
                                    value: item.id,
                                  },
                                })
                              }),
                              1
                            ),
                            _vm._v(" "),
                            _c(
                              "span",
                              {
                                on: {
                                  click: function ($event) {
                                    return _vm.Deletesshow("跟进人")
                                  },
                                },
                              },
                              [_c("i", { staticClass: "el-icon-close" })]
                            ),
                          ],
                          1
                        ),
                      ]
                    ),
                    _vm._v(" "),
                    _c(
                      "el-col",
                      { attrs: { xs: 24, sm: 16, md: 13, lg: 11, xl: 11 } },
                      [
                        _c(
                          "div",
                          {
                            directives: [
                              {
                                name: "show",
                                rawName: "v-show",
                                value: _vm.screen.DepartmentShow,
                                expression: "screen.DepartmentShow",
                              },
                            ],
                            staticStyle: { "margin-top": "15px" },
                          },
                          [
                            _c(
                              "span",
                              { staticClass: "nameTitle projectScreening" },
                              [_vm._v("数据所属部门：")]
                            ),
                            _vm._v(" "),
                            _c("el-cascader", {
                              attrs: {
                                placeholder: "请选择部门",
                                options: _vm.defaultArray.Department,
                                props: {
                                  value: "id",
                                  label: "name",
                                  multiple: true,
                                },
                                size: "small",
                                "collapse-tags": "",
                                clearable: "",
                              },
                              on: {
                                focus: _vm.ment,
                                change: _vm.DepartmentClick,
                              },
                              model: {
                                value: _vm.defaultValue.Department_id,
                                callback: function ($$v) {
                                  _vm.$set(
                                    _vm.defaultValue,
                                    "Department_id",
                                    $$v
                                  )
                                },
                                expression: "defaultValue.Department_id",
                              },
                            }),
                            _vm._v(" "),
                            _c(
                              "span",
                              {
                                on: {
                                  click: function ($event) {
                                    return _vm.Deletesshow("数据所属部门")
                                  },
                                },
                              },
                              [_c("i", { staticClass: "el-icon-close" })]
                            ),
                          ],
                          1
                        ),
                      ]
                    ),
                    _vm._v(" "),
                    _c(
                      "el-col",
                      { attrs: { xs: 24, sm: 16, md: 13, lg: 11, xl: 11 } },
                      [
                        _c(
                          "div",
                          {
                            directives: [
                              {
                                name: "show",
                                rawName: "v-show",
                                value: _vm.screen.creationDateShow,
                                expression: "screen.creationDateShow",
                              },
                            ],
                            staticStyle: { "margin-top": "15px" },
                          },
                          [
                            _c(
                              "span",
                              { staticClass: "nameTitle projectScreening" },
                              [_vm._v("创建日期：")]
                            ),
                            _vm._v(" "),
                            _c("el-date-picker", {
                              staticStyle: { width: "250px !important" },
                              attrs: {
                                size: "small",
                                type: "datetimerange",
                                "start-placeholder": "开始日期",
                                "end-placeholder": "  结束日期",
                                "default-time": ["00:00:00", "23:59:59"],
                                "value-format": "timestamp",
                              },
                              on: { change: _vm.creationDateTime },
                              model: {
                                value: _vm.defaultValue.creationDateName,
                                callback: function ($$v) {
                                  _vm.$set(
                                    _vm.defaultValue,
                                    "creationDateName",
                                    $$v
                                  )
                                },
                                expression: "defaultValue.creationDateName",
                              },
                            }),
                            _vm._v(" "),
                            _c(
                              "span",
                              {
                                on: {
                                  click: function ($event) {
                                    return _vm.Deletesshow("创建日期")
                                  },
                                },
                              },
                              [_c("i", { staticClass: "el-icon-close" })]
                            ),
                          ],
                          1
                        ),
                      ]
                    ),
                    _vm._v(" "),
                    _c(
                      "el-col",
                      { attrs: { xs: 24, sm: 16, md: 13, lg: 11, xl: 11 } },
                      [
                        _c(
                          "div",
                          {
                            directives: [
                              {
                                name: "show",
                                rawName: "v-show",
                                value: _vm.screen.attributionShow,
                                expression: "screen.attributionShow",
                              },
                            ],
                            staticStyle: { "margin-top": "15px" },
                          },
                          [
                            _c(
                              "span",
                              { staticClass: "nameTitle projectScreening" },
                              [_vm._v("归属地：")]
                            ),
                            _vm._v(" "),
                            _c(
                              "el-input",
                              {
                                staticStyle: { width: "250px" },
                                attrs: {
                                  placeholder: "多个归属地以空格分隔",
                                  size: "small",
                                },
                                model: {
                                  value: _vm.defaultValue.attributionName,
                                  callback: function ($$v) {
                                    _vm.$set(
                                      _vm.defaultValue,
                                      "attributionName",
                                      $$v
                                    )
                                  },
                                  expression: "defaultValue.attributionName",
                                },
                              },
                              [
                                _c("el-button", {
                                  attrs: {
                                    slot: "append",
                                    type: "primary",
                                    size: "small",
                                    icon: "el-icon-search",
                                  },
                                  on: { click: _vm.attributionClick },
                                  slot: "append",
                                }),
                              ],
                              1
                            ),
                            _vm._v(" "),
                            _c(
                              "span",
                              {
                                on: {
                                  click: function ($event) {
                                    return _vm.Deletesshow("归属地")
                                  },
                                },
                              },
                              [_c("i", { staticClass: "el-icon-close" })]
                            ),
                          ],
                          1
                        ),
                      ]
                    ),
                    _vm._v(" "),
                    _c(
                      "el-col",
                      { attrs: { xs: 24, sm: 16, md: 13, lg: 11, xl: 11 } },
                      [
                        _c(
                          "div",
                          {
                            directives: [
                              {
                                name: "show",
                                rawName: "v-show",
                                value: _vm.screen.founderShow,
                                expression: "screen.founderShow",
                              },
                            ],
                            staticStyle: { "margin-top": "15px" },
                          },
                          [
                            _c(
                              "span",
                              { staticClass: "nameTitle projectScreening" },
                              [_vm._v("创建人：")]
                            ),
                            _vm._v(" "),
                            _c(
                              "el-select",
                              {
                                directives: [
                                  {
                                    name: "loadmore",
                                    rawName: "v-loadmore",
                                    value: _vm.founderName,
                                    expression: "founderName",
                                  },
                                ],
                                ref: "clearValueEs",
                                staticStyle: { "margin-left": "20px" },
                                attrs: {
                                  multiple: "",
                                  filterable: "",
                                  remote: "",
                                  "reserve-keyword": "",
                                  size: "small",
                                  "collapse-tags": "",
                                  loading: _vm.screen.loading,
                                  "remote-method": _vm.founderMethod,
                                  placeholder: "请选择",
                                },
                                on: { change: _vm.founderEs },
                                model: {
                                  value: _vm.defaultValue.founder_Id,
                                  callback: function ($$v) {
                                    _vm.$set(
                                      _vm.defaultValue,
                                      "founder_Id",
                                      $$v
                                    )
                                  },
                                  expression: "defaultValue.founder_Id",
                                },
                              },
                              _vm._l(
                                _vm.defaultArray.founder_Arr,
                                function (item) {
                                  return _c("el-option", {
                                    key: item.id,
                                    attrs: {
                                      label: item.realname,
                                      value: item.id,
                                    },
                                  })
                                }
                              ),
                              1
                            ),
                            _vm._v(" "),
                            _c(
                              "span",
                              {
                                on: {
                                  click: function ($event) {
                                    return _vm.Deletesshow("创建人")
                                  },
                                },
                              },
                              [_c("i", { staticClass: "el-icon-close" })]
                            ),
                          ],
                          1
                        ),
                      ]
                    ),
                    _vm._v(" "),
                    _c(
                      "el-col",
                      { attrs: { xs: 24, sm: 16, md: 13, lg: 11, xl: 11 } },
                      [
                        _c(
                          "div",
                          {
                            directives: [
                              {
                                name: "show",
                                rawName: "v-show",
                                value: _vm.screen.founderEvelShow,
                                expression: "screen.founderEvelShow",
                              },
                            ],
                            staticStyle: { "margin-top": "15px" },
                          },
                          [
                            _c(
                              "span",
                              { staticClass: "nameTitle projectScreening" },
                              [_vm._v("创建人所属部门：")]
                            ),
                            _vm._v(" "),
                            _c("el-cascader", {
                              attrs: {
                                placeholder: "请选择部门",
                                options: _vm.defaultArray.founderEvelArrye,
                                props: {
                                  value: "id",
                                  label: "name",
                                  multiple: true,
                                },
                                size: "small",
                                "collapse-tags": "",
                                clearable: "",
                              },
                              on: {
                                focus: _vm.founderEvelFocus,
                                change: _vm.founderEvelClick,
                              },
                              model: {
                                value: _vm.defaultValue.founderEvel_id,
                                callback: function ($$v) {
                                  _vm.$set(
                                    _vm.defaultValue,
                                    "founderEvel_id",
                                    $$v
                                  )
                                },
                                expression: "defaultValue.founderEvel_id",
                              },
                            }),
                            _vm._v(" "),
                            _c(
                              "span",
                              {
                                on: {
                                  click: function ($event) {
                                    return _vm.Deletesshow("创建人所属部门")
                                  },
                                },
                              },
                              [_c("i", { staticClass: "el-icon-close" })]
                            ),
                          ],
                          1
                        ),
                      ]
                    ),
                    _vm._v(" "),
                    _c(
                      "el-col",
                      { attrs: { xs: 24, sm: 16, md: 13, lg: 11, xl: 11 } },
                      [
                        _c(
                          "div",
                          {
                            directives: [
                              {
                                name: "show",
                                rawName: "v-show",
                                value: _vm.screen.intentionShow,
                                expression: "screen.intentionShow",
                              },
                            ],
                            staticStyle: { "margin-top": "15px" },
                          },
                          [
                            _c(
                              "span",
                              { staticClass: "nameTitle projectScreening" },
                              [_vm._v("意向地区：")]
                            ),
                            _vm._v(" "),
                            _c(
                              "el-input",
                              {
                                staticStyle: { width: "250px" },
                                attrs: {
                                  placeholder: "请输入地区",
                                  size: "small",
                                },
                                on: { change: _vm.intentionClick },
                                nativeOn: {
                                  keyup: function ($event) {
                                    if (
                                      !$event.type.indexOf("key") &&
                                      _vm._k(
                                        $event.keyCode,
                                        "enter",
                                        13,
                                        $event.key,
                                        "Enter"
                                      )
                                    ) {
                                      return null
                                    }
                                    return _vm.intentionClick($event)
                                  },
                                },
                                model: {
                                  value: _vm.defaultValue.intentionName,
                                  callback: function ($$v) {
                                    _vm.$set(
                                      _vm.defaultValue,
                                      "intentionName",
                                      $$v
                                    )
                                  },
                                  expression: "defaultValue.intentionName",
                                },
                              },
                              [
                                _c("el-button", {
                                  attrs: {
                                    slot: "append",
                                    type: "primary",
                                    size: "small",
                                    icon: "el-icon-search",
                                  },
                                  on: { click: _vm.intentionClick },
                                  slot: "append",
                                }),
                              ],
                              1
                            ),
                            _vm._v(" "),
                            _c(
                              "span",
                              {
                                on: {
                                  click: function ($event) {
                                    return _vm.Deletesshow("意向地区")
                                  },
                                },
                              },
                              [_c("i", { staticClass: "el-icon-close" })]
                            ),
                          ],
                          1
                        ),
                      ]
                    ),
                    _vm._v(" "),
                    _c(
                      "el-col",
                      { attrs: { xs: 24, sm: 16, md: 13, lg: 11, xl: 11 } },
                      [
                        _c(
                          "div",
                          {
                            directives: [
                              {
                                name: "show",
                                rawName: "v-show",
                                value: _vm.screen.originalShow,
                                expression: "screen.originalShow",
                              },
                            ],
                            staticStyle: { "margin-top": "15px" },
                          },
                          [
                            _c(
                              "span",
                              { staticClass: "nameTitle projectScreening" },
                              [_vm._v("原始创建人：")]
                            ),
                            _vm._v(" "),
                            _c(
                              "el-input",
                              {
                                staticStyle: { width: "250px" },
                                attrs: {
                                  placeholder: "请输入名称",
                                  size: "small",
                                },
                                model: {
                                  value: _vm.defaultValue.originalName,
                                  callback: function ($$v) {
                                    _vm.$set(
                                      _vm.defaultValue,
                                      "originalName",
                                      $$v
                                    )
                                  },
                                  expression: "defaultValue.originalName",
                                },
                              },
                              [
                                _c("el-button", {
                                  attrs: {
                                    slot: "append",
                                    type: "primary",
                                    size: "small",
                                    icon: "el-icon-search",
                                  },
                                  on: { click: _vm.originalClick },
                                  slot: "append",
                                }),
                              ],
                              1
                            ),
                            _vm._v(" "),
                            _c(
                              "span",
                              {
                                on: {
                                  click: function ($event) {
                                    return _vm.Deletesshow("原始创建人")
                                  },
                                },
                              },
                              [_c("i", { staticClass: "el-icon-close" })]
                            ),
                          ],
                          1
                        ),
                      ]
                    ),
                    _vm._v(" "),
                    _c(
                      "el-col",
                      { attrs: { xs: 24, sm: 16, md: 13, lg: 11, xl: 11 } },
                      [
                        _c(
                          "div",
                          {
                            directives: [
                              {
                                name: "show",
                                rawName: "v-show",
                                value: _vm.screen.lastFollowUpShow,
                                expression: "screen.lastFollowUpShow",
                              },
                            ],
                            staticStyle: { "margin-top": "15px" },
                          },
                          [
                            _c(
                              "span",
                              { staticClass: "nameTitle projectScreening" },
                              [_vm._v("最后跟进日期：")]
                            ),
                            _vm._v(" "),
                            _c("el-date-picker", {
                              staticStyle: { width: "250px !important" },
                              attrs: {
                                size: "small",
                                type: "datetimerange",
                                "start-placeholder": "开始日期",
                                "end-placeholder": "  结束日期",
                                "default-time": ["00:00:00", "23:59:59"],
                                "value-format": "timestamp",
                              },
                              on: { change: _vm.lastFollowUpClick },
                              model: {
                                value: _vm.defaultValue.lastFollowUpDate,
                                callback: function ($$v) {
                                  _vm.$set(
                                    _vm.defaultValue,
                                    "lastFollowUpDate",
                                    $$v
                                  )
                                },
                                expression: "defaultValue.lastFollowUpDate",
                              },
                            }),
                            _vm._v(" "),
                            _c(
                              "span",
                              {
                                on: {
                                  click: function ($event) {
                                    return _vm.Deletesshow("最后跟进日期")
                                  },
                                },
                              },
                              [_c("i", { staticClass: "el-icon-close" })]
                            ),
                          ],
                          1
                        ),
                      ]
                    ),
                    _vm._v(" "),
                    _c(
                      "el-col",
                      { attrs: { xs: 24, sm: 16, md: 13, lg: 11, xl: 11 } },
                      [
                        _c(
                          "div",
                          {
                            directives: [
                              {
                                name: "show",
                                rawName: "v-show",
                                value: _vm.screen.thePrivateSeaShow,
                                expression: "screen.thePrivateSeaShow",
                              },
                            ],
                            staticStyle: { "margin-top": "15px" },
                          },
                          [
                            _c(
                              "span",
                              { staticClass: "nameTitle projectScreening" },
                              [_vm._v("进入私海日期：")]
                            ),
                            _vm._v(" "),
                            _c("el-date-picker", {
                              staticStyle: { width: "250px !important" },
                              attrs: {
                                size: "small",
                                type: "datetimerange",
                                "start-placeholder": "开始日期",
                                "end-placeholder": "  结束日期",
                                "default-time": ["00:00:00", "23:59:59"],
                                "value-format": "timestamp",
                              },
                              on: { change: _vm.thePrivateSeaClick },
                              model: {
                                value: _vm.defaultValue.thePrivateSea,
                                callback: function ($$v) {
                                  _vm.$set(
                                    _vm.defaultValue,
                                    "thePrivateSea",
                                    $$v
                                  )
                                },
                                expression: "defaultValue.thePrivateSea",
                              },
                            }),
                            _vm._v(" "),
                            _c(
                              "span",
                              {
                                on: {
                                  click: function ($event) {
                                    return _vm.Deletesshow("进入私海日期")
                                  },
                                },
                              },
                              [_c("i", { staticClass: "el-icon-close" })]
                            ),
                          ],
                          1
                        ),
                      ]
                    ),
                    _vm._v(" "),
                    _c(
                      "el-col",
                      { attrs: { xs: 24, sm: 16, md: 13, lg: 11, xl: 11 } },
                      [
                        _c(
                          "div",
                          {
                            directives: [
                              {
                                name: "show",
                                rawName: "v-show",
                                value: _vm.screen.lastUpdateShow,
                                expression: "screen.lastUpdateShow",
                              },
                            ],
                            staticStyle: { "margin-top": "15px" },
                          },
                          [
                            _c(
                              "span",
                              { staticClass: "nameTitle projectScreening" },
                              [_vm._v("最后更新日期：")]
                            ),
                            _vm._v(" "),
                            _c("el-date-picker", {
                              staticStyle: { width: "250px !important" },
                              attrs: {
                                size: "small",
                                type: "datetimerange",
                                "start-placeholder": "开始日期",
                                "end-placeholder": "  结束日期",
                                "default-time": ["00:00:00", "23:59:59"],
                                "value-format": "timestamp",
                              },
                              on: { change: _vm.lastUpdateClick },
                              model: {
                                value: _vm.defaultValue.lastUpdate,
                                callback: function ($$v) {
                                  _vm.$set(_vm.defaultValue, "lastUpdate", $$v)
                                },
                                expression: "defaultValue.lastUpdate",
                              },
                            }),
                            _vm._v(" "),
                            _c(
                              "span",
                              {
                                on: {
                                  click: function ($event) {
                                    return _vm.Deletesshow("最后更新日期")
                                  },
                                },
                              },
                              [_c("i", { staticClass: "el-icon-close" })]
                            ),
                          ],
                          1
                        ),
                      ]
                    ),
                    _vm._v(" "),
                    _c(
                      "el-col",
                      { attrs: { xs: 24, sm: 16, md: 13, lg: 11, xl: 11 } },
                      [
                        _c(
                          "div",
                          {
                            directives: [
                              {
                                name: "show",
                                rawName: "v-show",
                                value: _vm.comPlatshow,
                                expression: "comPlatshow",
                              },
                            ],
                            staticStyle: { "margin-top": "15px" },
                          },
                          [
                            _c("span", { staticClass: "nameTitle" }, [
                              _vm._v("推广平台："),
                            ]),
                            _vm._v(" "),
                            _c(
                              "el-input",
                              {
                                staticStyle: { width: "250px" },
                                attrs: {
                                  placeholder: "请搜索推广平台",
                                  size: "small",
                                },
                                on: { change: _vm.comPlatClick },
                                nativeOn: {
                                  keyup: function ($event) {
                                    if (
                                      !$event.type.indexOf("key") &&
                                      _vm._k(
                                        $event.keyCode,
                                        "enter",
                                        13,
                                        $event.key,
                                        "Enter"
                                      )
                                    ) {
                                      return null
                                    }
                                    return _vm.comPlatClick($event)
                                  },
                                },
                                model: {
                                  value: _vm.comPlat,
                                  callback: function ($$v) {
                                    _vm.comPlat = $$v
                                  },
                                  expression: "comPlat",
                                },
                              },
                              [
                                _c("el-button", {
                                  attrs: {
                                    slot: "append",
                                    type: "primary",
                                    size: "small",
                                    icon: "el-icon-search",
                                  },
                                  on: { click: _vm.comPlatClick },
                                  slot: "append",
                                }),
                              ],
                              1
                            ),
                            _vm._v(" "),
                            _c(
                              "span",
                              {
                                on: {
                                  click: function ($event) {
                                    return _vm.Deletesshow("推广平台")
                                  },
                                },
                              },
                              [_c("i", { staticClass: "el-icon-close" })]
                            ),
                          ],
                          1
                        ),
                      ]
                    ),
                    _vm._v(" "),
                    _c(
                      "el-col",
                      { attrs: { xs: 24, sm: 16, md: 13, lg: 11, xl: 11 } },
                      [
                        _c(
                          "div",
                          {
                            directives: [
                              {
                                name: "show",
                                rawName: "v-show",
                                value: _vm.comNameshow,
                                expression: "comNameshow",
                              },
                            ],
                            staticStyle: { "margin-top": "15px" },
                          },
                          [
                            _c("span", { staticClass: "nameTitle" }, [
                              _vm._v("推广项目："),
                            ]),
                            _vm._v(" "),
                            _c(
                              "el-input",
                              {
                                staticStyle: { width: "250px" },
                                attrs: {
                                  placeholder: "请搜索推广项目",
                                  size: "small",
                                },
                                on: { change: _vm.comNameClick },
                                nativeOn: {
                                  keyup: function ($event) {
                                    if (
                                      !$event.type.indexOf("key") &&
                                      _vm._k(
                                        $event.keyCode,
                                        "enter",
                                        13,
                                        $event.key,
                                        "Enter"
                                      )
                                    ) {
                                      return null
                                    }
                                    return _vm.comNameClick($event)
                                  },
                                },
                                model: {
                                  value: _vm.comName,
                                  callback: function ($$v) {
                                    _vm.comName = $$v
                                  },
                                  expression: "comName",
                                },
                              },
                              [
                                _c("el-button", {
                                  attrs: {
                                    slot: "append",
                                    type: "primary",
                                    size: "small",
                                    icon: "el-icon-search",
                                  },
                                  on: { click: _vm.comNameClick },
                                  slot: "append",
                                }),
                              ],
                              1
                            ),
                            _vm._v(" "),
                            _c(
                              "span",
                              {
                                on: {
                                  click: function ($event) {
                                    return _vm.Deletesshow("推广项目")
                                  },
                                },
                              },
                              [_c("i", { staticClass: "el-icon-close" })]
                            ),
                          ],
                          1
                        ),
                      ]
                    ),
                    _vm._v(" "),
                    _c(
                      "el-col",
                      { attrs: { xs: 24, sm: 16, md: 13, lg: 11, xl: 11 } },
                      [
                        _c(
                          "div",
                          {
                            directives: [
                              {
                                name: "show",
                                rawName: "v-show",
                                value: _vm.comWebsiteshow,
                                expression: "comWebsiteshow",
                              },
                            ],
                            staticStyle: { "margin-top": "15px" },
                          },
                          [
                            _c("span", { staticClass: "nameTitle" }, [
                              _vm._v("推广地址："),
                            ]),
                            _vm._v(" "),
                            _c(
                              "el-input",
                              {
                                staticStyle: { width: "250px" },
                                attrs: {
                                  placeholder: "请搜索推广地址",
                                  size: "small",
                                },
                                on: { change: _vm.comWebsiteClick },
                                nativeOn: {
                                  keyup: function ($event) {
                                    if (
                                      !$event.type.indexOf("key") &&
                                      _vm._k(
                                        $event.keyCode,
                                        "enter",
                                        13,
                                        $event.key,
                                        "Enter"
                                      )
                                    ) {
                                      return null
                                    }
                                    return _vm.comWebsiteClick($event)
                                  },
                                },
                                model: {
                                  value: _vm.comWebsite,
                                  callback: function ($$v) {
                                    _vm.comWebsite = $$v
                                  },
                                  expression: "comWebsite",
                                },
                              },
                              [
                                _c("el-button", {
                                  attrs: {
                                    slot: "append",
                                    type: "primary",
                                    size: "small",
                                    icon: "el-icon-search",
                                  },
                                  on: { click: _vm.comWebsiteClick },
                                  slot: "append",
                                }),
                              ],
                              1
                            ),
                            _vm._v(" "),
                            _c(
                              "span",
                              {
                                on: {
                                  click: function ($event) {
                                    return _vm.Deletesshow("推广地址")
                                  },
                                },
                              },
                              [_c("i", { staticClass: "el-icon-close" })]
                            ),
                          ],
                          1
                        ),
                      ]
                    ),
                  ],
                  1
                ),
              ],
              1
            ),
            _vm._v(" "),
            _vm.seeJudge
              ? _c(
                  "el-main",
                  [
                    [
                      _c(
                        "el-table",
                        {
                          directives: [
                            {
                              name: "loading",
                              rawName: "v-loading",
                              value: _vm.listLoading,
                              expression: "listLoading",
                            },
                          ],
                          ref: "multipleTable",
                          staticStyle: { width: "100%" },
                          attrs: {
                            data: _vm.tableData,
                            fit: "",
                            border: "",
                            id: "customerTable",
                            "header-cell-style": {
                              background: "#F8F9FB",
                              color: "#222222",
                            },
                          },
                          on: {
                            "sort-change": _vm.sortEs,
                            "selection-change": _vm.handleSelectionChange,
                          },
                        },
                        [
                          _c("el-table-column", {
                            key: 0,
                            attrs: {
                              label: "序号",
                              fixed: "",
                              align: "center",
                            },
                            scopedSlots: _vm._u(
                              [
                                {
                                  key: "default",
                                  fn: function (scope) {
                                    return [
                                      _c("span", [
                                        _vm._v(
                                          _vm._s(
                                            scope.$index +
                                              (_vm.query.page - 1) *
                                                _vm.query.pagesize +
                                              1
                                          )
                                        ),
                                      ]),
                                    ]
                                  },
                                },
                              ],
                              null,
                              false,
                              3946342523
                            ),
                          }),
                          _vm._v(" "),
                          _c("el-table-column", {
                            key: 1,
                            attrs: {
                              label: "客户名称",
                              fixed: "",
                              prop: "cname",
                              align: "center",
                            },
                            scopedSlots: _vm._u(
                              [
                                {
                                  key: "default",
                                  fn: function (scope) {
                                    return [
                                      _c(
                                        "a",
                                        {
                                          staticStyle: { color: "#1890FF" },
                                          on: {
                                            click: function ($event) {
                                              return _vm.followUpCustomers(
                                                scope.row
                                              )
                                            },
                                          },
                                        },
                                        [
                                          _c("span", [
                                            _vm._v(_vm._s(scope.row.cname)),
                                          ]),
                                        ]
                                      ),
                                    ]
                                  },
                                },
                              ],
                              null,
                              false,
                              2689176135
                            ),
                          }),
                          _vm._v(" "),
                          _c("el-table-column", {
                            key: 2,
                            attrs: {
                              label: "学员级别",
                              fixed: "",
                              prop: "customer_level",
                              align: "center",
                            },
                          }),
                          _vm._v(" "),
                          _c("el-table-column", {
                            key: 3,
                            attrs: {
                              label: "学员来源",
                              fixed: "",
                              prop: "client_name",
                              align: "center",
                            },
                          }),
                          _vm._v(" "),
                          _c(
                            "el-table-column",
                            {
                              key: 4,
                              attrs: {
                                label: "跟进人",
                                prop: "follow_realname",
                                align: "center",
                              },
                            },
                            [
                              _vm.searchJudge
                                ? _c("template", { slot: "header" }, [
                                    _c(
                                      "span",
                                      {
                                        staticStyle: {
                                          cursor: "pointer",
                                          color: "#1890FF",
                                        },
                                        on: {
                                          click: function ($event) {
                                            return _vm.Activeshow("跟进人")
                                          },
                                        },
                                      },
                                      [_vm._v("跟进人")]
                                    ),
                                  ])
                                : _vm._e(),
                            ],
                            2
                          ),
                          _vm._v(" "),
                          _c(
                            "el-table-column",
                            {
                              key: 5,
                              attrs: {
                                label: "数据所属部门",
                                prop: "follow_structure",
                                align: "center",
                              },
                            },
                            [
                              _vm.searchJudge
                                ? _c("template", { slot: "header" }, [
                                    _c(
                                      "span",
                                      {
                                        staticStyle: {
                                          cursor: "pointer",
                                          color: "#1890FF",
                                        },
                                        on: {
                                          click: function ($event) {
                                            return _vm.Activeshow(
                                              "数据所属部门"
                                            )
                                          },
                                        },
                                      },
                                      [_vm._v("数据所属部门")]
                                    ),
                                  ])
                                : _vm._e(),
                            ],
                            2
                          ),
                          _vm._v(" "),
                          _vm.searchJudge
                            ? _c(
                                "el-table-column",
                                {
                                  key: 6,
                                  attrs: {
                                    label: "创建日期",
                                    sortable: "custom",
                                    "min-width": "150",
                                    width: "170",
                                    prop: "create_time",
                                    align: "center",
                                    filters: [],
                                  },
                                },
                                [
                                  _c("template", { slot: "header" }, [
                                    _c(
                                      "span",
                                      {
                                        staticStyle: {
                                          cursor: "pointer",
                                          color: "#1890FF",
                                        },
                                        on: {
                                          click: function ($event) {
                                            return _vm.Activeshow("创建日期")
                                          },
                                        },
                                      },
                                      [_vm._v("创建日期")]
                                    ),
                                  ]),
                                ],
                                2
                              )
                            : _c("el-table-column", {
                                key: 6,
                                attrs: {
                                  label: "创建日期",
                                  "min-width": "150",
                                  width: "170",
                                  prop: "create_time",
                                  align: "center",
                                },
                              }),
                          _vm._v(" "),
                          _c("el-table-column", {
                            key: 7,
                            attrs: {
                              label: "客户电话",
                              prop: "mobile",
                              align: "center",
                            },
                          }),
                          _vm._v(" "),
                          _c(
                            "el-table-column",
                            {
                              key: 8,
                              attrs: {
                                label: "归属地",
                                prop: "mobile_address",
                                align: "center",
                              },
                            },
                            [
                              _vm.searchJudge
                                ? _c("template", { slot: "header" }, [
                                    _c(
                                      "span",
                                      {
                                        staticStyle: {
                                          cursor: "pointer",
                                          color: "#1890FF",
                                        },
                                        on: {
                                          click: function ($event) {
                                            return _vm.Activeshow("归属地")
                                          },
                                        },
                                      },
                                      [_vm._v("归属地")]
                                    ),
                                  ])
                                : _vm._e(),
                            ],
                            2
                          ),
                          _vm._v(" "),
                          _c(
                            "el-table-column",
                            {
                              key: 9,
                              attrs: {
                                label: "创建人",
                                prop: "create_realname",
                                align: "center",
                              },
                            },
                            [
                              _vm.searchJudge
                                ? _c("template", { slot: "header" }, [
                                    _c(
                                      "span",
                                      {
                                        staticStyle: {
                                          cursor: "pointer",
                                          color: "#1890FF",
                                        },
                                        on: {
                                          click: function ($event) {
                                            return _vm.Activeshow("创建人")
                                          },
                                        },
                                      },
                                      [_vm._v("创建人")]
                                    ),
                                  ])
                                : _vm._e(),
                            ],
                            2
                          ),
                          _vm._v(" "),
                          _vm.is_director == 1
                            ? _c(
                                "el-table-column",
                                {
                                  key: 10,
                                  attrs: {
                                    label: "创建人所属部门",
                                    prop: "create_structure",
                                    align: "center",
                                  },
                                },
                                [
                                  _vm.searchJudge
                                    ? _c("template", { slot: "header" }, [
                                        _c(
                                          "span",
                                          {
                                            staticStyle: {
                                              cursor: "pointer",
                                              color: "#1890FF",
                                            },
                                            on: {
                                              click: function ($event) {
                                                return _vm.Activeshow(
                                                  "创建人所属部门"
                                                )
                                              },
                                            },
                                          },
                                          [_vm._v("创建人所属部门")]
                                        ),
                                      ])
                                    : _vm._e(),
                                ],
                                2
                              )
                            : _vm._e(),
                          _vm._v(" "),
                          _vm.is_director == 0
                            ? _c("el-table-column", {
                                key: 10,
                                attrs: {
                                  label: "创建人所属部门",
                                  prop: "create_structure",
                                  align: "center",
                                },
                              })
                            : _vm._e(),
                          _vm._v(" "),
                          _c(
                            "el-table-column",
                            {
                              key: 11,
                              attrs: {
                                label: "意向地区",
                                prop: "ittngj",
                                align: "center",
                              },
                            },
                            [
                              _vm.searchJudge
                                ? _c("template", { slot: "header" }, [
                                    _c(
                                      "span",
                                      {
                                        staticStyle: {
                                          cursor: "pointer",
                                          color: "#1890FF",
                                        },
                                        on: {
                                          click: function ($event) {
                                            return _vm.Activeshow("意向地区")
                                          },
                                        },
                                      },
                                      [_vm._v("意向地区")]
                                    ),
                                  ])
                                : _vm._e(),
                            ],
                            2
                          ),
                          _vm._v(" "),
                          _vm.searchJudge
                            ? _c(
                                "el-table-column",
                                {
                                  key: 12,
                                  attrs: {
                                    label: "最后跟进日期",
                                    "min-width": "150",
                                    width: "170",
                                    sortable: "custom",
                                    prop: "follw_time",
                                    align: "center",
                                    filters: [],
                                  },
                                },
                                [
                                  _c("template", { slot: "header" }, [
                                    _c(
                                      "span",
                                      {
                                        staticStyle: {
                                          cursor: "pointer",
                                          color: "#1890FF",
                                        },
                                        on: {
                                          click: function ($event) {
                                            return _vm.Activeshow(
                                              "最后跟进日期"
                                            )
                                          },
                                        },
                                      },
                                      [_vm._v("最后跟进日期")]
                                    ),
                                  ]),
                                ],
                                2
                              )
                            : _c("el-table-column", {
                                key: 12,
                                attrs: {
                                  label: "最后跟进日期",
                                  "min-width": "150",
                                  width: "170",
                                  prop: "follw_time",
                                  align: "center",
                                },
                              }),
                          _vm._v(" "),
                          _vm.searchJudge
                            ? _c(
                                "el-table-column",
                                {
                                  key: 13,
                                  attrs: {
                                    label: "进入私海日期",
                                    "min-width": "150",
                                    width: "170",
                                    sortable: "custom",
                                    prop: "sea_time",
                                    align: "center",
                                    filters: [],
                                  },
                                },
                                [
                                  _c("template", { slot: "header" }, [
                                    _c(
                                      "span",
                                      {
                                        staticStyle: {
                                          cursor: "pointer",
                                          color: "#1890FF",
                                        },
                                        on: {
                                          click: function ($event) {
                                            return _vm.Activeshow(
                                              "进入私海日期"
                                            )
                                          },
                                        },
                                      },
                                      [_vm._v("进入私海日期")]
                                    ),
                                  ]),
                                ],
                                2
                              )
                            : _c("el-table-column", {
                                key: 13,
                                attrs: {
                                  label: "进入私海日期",
                                  "min-width": "150",
                                  width: "170",
                                  prop: "sea_time",
                                  align: "center",
                                },
                              }),
                          _vm._v(" "),
                          _vm.searchJudge
                            ? _c(
                                "el-table-column",
                                {
                                  key: 14,
                                  attrs: {
                                    label: "最后更新日期",
                                    "min-width": "150",
                                    width: "170",
                                    sortable: "custom",
                                    prop: "update_time",
                                    align: "center",
                                    filters: [],
                                  },
                                },
                                [
                                  _c("template", { slot: "header" }, [
                                    _c(
                                      "span",
                                      {
                                        staticStyle: {
                                          cursor: "pointer",
                                          color: "#1890FF",
                                        },
                                        on: {
                                          click: function ($event) {
                                            return _vm.Activeshow(
                                              "最后更新日期"
                                            )
                                          },
                                        },
                                      },
                                      [_vm._v("最后更新日期")]
                                    ),
                                  ]),
                                ],
                                2
                              )
                            : _c("el-table-column", {
                                key: 14,
                                attrs: {
                                  label: "最后更新日期",
                                  "min-width": "150",
                                  width: "170",
                                  prop: "update_time",
                                  align: "center",
                                },
                              }),
                          _vm._v(" "),
                          _c(
                            "el-table-column",
                            {
                              key: 15,
                              attrs: {
                                label: "原始创建人",
                                prop: "old_create_realname",
                                align: "center",
                              },
                            },
                            [
                              _vm.searchJudge
                                ? _c("template", { slot: "header" }, [
                                    _c(
                                      "span",
                                      {
                                        staticStyle: {
                                          cursor: "pointer",
                                          color: "#1890FF",
                                        },
                                        on: {
                                          click: function ($event) {
                                            return _vm.Activeshow("原始创建人")
                                          },
                                        },
                                      },
                                      [_vm._v("原始创建人")]
                                    ),
                                  ])
                                : _vm._e(),
                            ],
                            2
                          ),
                          _vm._v(" "),
                          _c(
                            "el-table-column",
                            {
                              key: 57,
                              attrs: {
                                label: "推广平台",
                                prop: "platform_name",
                                align: "center",
                                width: "120px",
                              },
                            },
                            [
                              _c("template", { slot: "header" }, [
                                _c(
                                  "span",
                                  {
                                    staticStyle: {
                                      cursor: "pointer",
                                      color: "#1890FF",
                                    },
                                    on: {
                                      click: function ($event) {
                                        return _vm.Activeshow("推广平台")
                                      },
                                    },
                                  },
                                  [_vm._v("推广平台")]
                                ),
                              ]),
                            ],
                            2
                          ),
                          _vm._v(" "),
                          _c(
                            "el-table-column",
                            {
                              key: 55,
                              attrs: {
                                label: "推广项目",
                                prop: "communication_name",
                                align: "center",
                                width: "120px",
                              },
                              scopedSlots: _vm._u(
                                [
                                  {
                                    key: "default",
                                    fn: function (scope) {
                                      return [
                                        _c("span", [
                                          _vm._v(
                                            _vm._s(scope.row.communication_name)
                                          ),
                                        ]),
                                      ]
                                    },
                                  },
                                ],
                                null,
                                false,
                                774335934
                              ),
                            },
                            [
                              _vm._v(" "),
                              _c("template", { slot: "header" }, [
                                _c(
                                  "span",
                                  {
                                    staticStyle: {
                                      cursor: "pointer",
                                      color: "#1890FF",
                                    },
                                    on: {
                                      click: function ($event) {
                                        return _vm.Activeshow("推广项目")
                                      },
                                    },
                                  },
                                  [_vm._v("推广项目")]
                                ),
                              ]),
                            ],
                            2
                          ),
                          _vm._v(" "),
                          _c(
                            "el-table-column",
                            {
                              key: 56,
                              attrs: {
                                label: "推广地址",
                                prop: "communication_website",
                                align: "center",
                                width: "120px",
                              },
                              scopedSlots: _vm._u(
                                [
                                  {
                                    key: "default",
                                    fn: function (scope) {
                                      return [
                                        scope.row.communication_website.length >
                                        30
                                          ? _c(
                                              "el-tooltip",
                                              {
                                                staticClass: "itemDes",
                                                attrs: {
                                                  content:
                                                    scope.row
                                                      .communication_website,
                                                  "popper-class": "toolp",
                                                  placement: "top-start",
                                                  effect: "light",
                                                },
                                              },
                                              [
                                                _c("span", [
                                                  _vm._v(
                                                    _vm._s(
                                                      scope.row.communication_website.slice(
                                                        0,
                                                        30
                                                      ) + "..."
                                                    )
                                                  ),
                                                ]),
                                              ]
                                            )
                                          : _c("span", [
                                              _vm._v(
                                                _vm._s(
                                                  scope.row
                                                    .communication_website
                                                )
                                              ),
                                            ]),
                                      ]
                                    },
                                  },
                                ],
                                null,
                                false,
                                1042608129
                              ),
                            },
                            [
                              _vm._v(" "),
                              _c("template", { slot: "header" }, [
                                _c(
                                  "span",
                                  {
                                    staticStyle: {
                                      cursor: "pointer",
                                      color: "#1890FF",
                                    },
                                    on: {
                                      click: function ($event) {
                                        return _vm.Activeshow("推广地址")
                                      },
                                    },
                                  },
                                  [_vm._v("推广地址")]
                                ),
                              ]),
                            ],
                            2
                          ),
                        ],
                        1
                      ),
                      _vm._v(" "),
                      _c(
                        "div",
                        {
                          ref: "tableLeft",
                          attrs: { id: "tableLeft" },
                          on: {
                            scroll: function ($event) {
                              return _vm.exterHandleScroll()
                            },
                          },
                        },
                        [
                          _c("div", {
                            staticStyle: { height: "10px" },
                            attrs: { id: "bodyLeft" },
                          }),
                        ]
                      ),
                    ],
                  ],
                  2
                )
              : _vm._e(),
            _vm._v(" "),
            _c("pagination", {
              attrs: {
                "current-page": _vm.query.page,
                totalPage: _vm.query.total,
                pageSize: _vm.query.pagesize,
              },
              on: {
                handleSizeChange: _vm.handleSizeChange,
                handleCurrentChange: _vm.handleCurrentChange,
              },
            }),
            _vm._v(" "),
            _c(
              "el-dialog",
              {
                attrs: { title: "跟进", visible: _vm.followUp },
                on: {
                  "update:visible": function ($event) {
                    _vm.followUp = $event
                  },
                },
              },
              [
                _c(
                  "el-row",
                  [
                    _c(
                      "el-col",
                      { attrs: { span: 24 } },
                      [
                        _c("h3", [_vm._v("历史跟进记录:")]),
                        _vm._v(" "),
                        _vm.activities.length == 0
                          ? _c(
                              "p",
                              { staticStyle: { "text-align": "center" } },
                              [_vm._v("暂无跟进记录")]
                            )
                          : _c(
                              "el-timeline",
                              _vm._l(
                                _vm.activities,
                                function (activity, index) {
                                  return _c(
                                    "el-timeline-item",
                                    {
                                      key: index,
                                      attrs: {
                                        placement: "top",
                                        timestamp: activity.create_time,
                                      },
                                    },
                                    [
                                      _c(
                                        "span",
                                        { staticClass: "eD8CF0 mr10" },
                                        [_vm._v(_vm._s(activity.type))]
                                      ),
                                      _vm._v(" "),
                                      _c("span", [
                                        _vm._v(
                                          _vm._s(activity.create_realnaem)
                                        ),
                                      ]),
                                      _vm._v(" "),
                                      _c("br"),
                                      _c("br"),
                                      _vm._v(" "),
                                      _c("span", [
                                        _vm._v(
                                          "下次回访日期：" +
                                            _vm._s(activity.next_time)
                                        ),
                                      ]),
                                      _vm._v(" "),
                                      _c("br"),
                                      _vm._v(" "),
                                      _c("br"),
                                      _vm._v(" "),
                                      _c("p", [
                                        _vm._v(_vm._s(activity.content)),
                                      ]),
                                    ]
                                  )
                                }
                              ),
                              1
                            ),
                      ],
                      1
                    ),
                  ],
                  1
                ),
              ],
              1
            ),
          ],
          1
        )
      : _c(
          "div",
          {
            staticClass: "boxImg",
            staticStyle: { width: "100%", padding: "20px" },
          },
          [_c("system", { attrs: { title: _vm.title, tab: _vm.tab } })],
          1
        ),
  ])
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }